@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .a4 {
        height: 297mm;
        max-height: 297mm;
        width: 210mm;
        max-width: 210mm;
    }
}

@layer utilities {
    .a4-flex {
        width: 210mm;
        max-width: 210mm;
        min-height: 297mm;
    }
}
